<template>
  <v-data-table
    must-sort
    fixed-header
    :loading="loading"
    :headers="headers"
    :items="resistances"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 25, 50 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('resistance', 2)) } ${ $t('per page') }:`
    }"
    class="elevation-2"
    show-expand
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({ resistances: 'edit' })"
            color="success"
            fab
            small
            xv-on="on"
            class="mt-n5"
            xelevation="4"
            @click="$emit('toggle-right-drawer', {}, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length - 1">
        <v-col>
          <div class="text-caption">
            Genes
          </div>
          <gene-chip
            v-for="(gene, index) in orderBy(item.genes.filter(x => x.gene), 'gene')"
            :key="index"
            :gene="gene"
            class="mr-1 mb-1 px-2"
            x-small
          />
        </v-col>
      </td>
    </template>
    <template #item.name="{ item }">
      <div
        class="text-no-wrap"
        v-text="item.name.en"
      />
    </template>
    <template #item.genes="{ item }">
      {{ item.genes.filter(x => x.gene).length }}
    </template>
    <template #item.score="{ item }">
      <arkscore-gauge
        :key="'arkscore-gauge-' + item.score"
        :value="item.score"
        :text="item.score"
        class="my-2"
      />
    </template>
    <template
      v-if="$auth.check({ resistances: 'edit' })"
      #item.actions="{ item }"
    >
      <v-btn
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters';

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
      GeneChip: () => import('@/components/GeneChip.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Resistance Genes',
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          sortBy: ['name'],
          sortDesc: [false],
        },
        detailDialog: {},
        totalItems: null,
        headers: [
          {
            text: 'Resistance',
            value: 'name',
          },
          {
            text: 'Genes',
            value: 'genes',
            sortable: false,
          },
          {
            text: 'Description',
            value: 'description',
          },
          {
            text: 'ArkScore',
            value: 'score',
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        resistances: [],
        resistanceForm: {},
        resistanceEditDialog: false,
        colors: {
          1: '#00814e',
          2: '#4cb648',
          3: '#c6d935',
          4: '#ffcc05',
          5: '#f79839',
          6: '#ec2024',
          7: '#ae1618',
        },
      }
    },
    watch: {

      options: {
        handler () {
          this.fetchResistances()
        },
      },
    },
    methods: {
      fetchResistances () {
        this.loading = true
        this.axios.get('admin/resistances', {
          params:
            {
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
        }).then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchResistances()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.resistances = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
        })
      },
      updateResistance (resistance) {
        this.axios.patch('admin/resistances/' + resistance.id, resistance)
      },
      editResistance (item) {
        this.axios.get('admin/resistances/' + item.id)
          .then(response => {
            this.resistanceForm = response.data
            this.resistanceEditDialog = true
          })
      },
      deleteResistance (item) {
        this.$confirm('Delete ' + item.first_name + ' ' + item.last_name + '?').then(res => {
          if (res) {
            this.axios
              .delete('admin/resistances/' + item.id)
              .then(() => {
                const index = this.resistances.indexOf(item)
                this.$delete(this.resistances, index)
                this.totalItems--
              })
          }
        })
      },
      detailModal (title, data) {
        this.detailDialog = {
          open: true,
          title: title,
          id: data.id,
        }
      },
      colorGauge (weight) {
        var color1 = weight > 50 ? [0, 204, 0] : [255, 165, 0]
        var color2 = weight > 50 ? [255, 165, 0] : [255, 0, 0]
        var p = weight > 50 ? (weight - 50) / 50 : weight / 50
        var w = p * 2 - 1
        var w1 = (w / 1 + 1) / 2
        var w2 = 1 - w1
        var rgb = [
          Math.round(color1[0] * w1 + color2[0] * w2),
          Math.round(color1[1] * w1 + color2[1] * w2),
          Math.round(color1[2] * w1 + color2[2] * w2),
        ]
        return 'rgb(' + rgb.join() + ')'
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4em;
  padding-top: 1.1em;

  span {
    max-width: 100px;
  }
}
</style>
